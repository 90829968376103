<template>
  <div>
    <EmbeddedUploadModal ref="uploadModal"/>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { getInfo } from 'api/embedded'
import { getUserInfo } from 'api/user'
import { getDetail } from 'api/documents'
import { useRoute, useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue'
import { canSign, clearLinkSessions, clearUserInfo, signFromLink } from '@/utils/helper'
import EmbeddedUploadModal from 'components/signing/EmbeddedUploadModal'
import { useStore } from 'vuex'
import createStorage from 'utils/storage'

export default {
  components: {
    EmbeddedUploadModal
  },

  setup () {
    const uploadModal = ref(null)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const storage = createStorage('sessionStorage')

    onMounted(() => {
      const index = route.params.index
      getInfo(index)
        .then((res) => {
          clearLinkSessions()
          clearUserInfo()
          const data = res.result
          switch (data.action) {
            case 'sign_creating':
              createAction(data)
              break
            case 'view':
              viewAction(data)
              break
            case 'template_creating':
              createTemplateAction(data)
              break
            case 'template_modifying':
              temlateModifyAction(data)
              break
            default:
              errorModal(null)
          }
        }).catch((err) => {
          errorModal(err)
        })
    })

    const createAction = data => {
      const redirectUrl = data.redirectUrl ? encodeURIComponent(data.redirectUrl) : null
      const customValue = data.customValue ? encodeURIComponent(data.customValue) : null
      setToken(data)
      getUserInfo().then((res) => {
        const { result } = res
        store.commit('setUserInfo', result)
        uploadModal.value.show('', redirectUrl, customValue)
      }).catch((err) => {
        errorModal(err)
      })
    }

    const viewAction = data => {
      const redirectUrl = data.redirectUrl ? encodeURIComponent(data.redirectUrl) : null
      const di = data.documentId
      const pi = data.participantId
      if (pi) {
        setAnonUser(data)
      } else {
        setToken(data)
      }
      getDetail(di).then(res => {
        const [isFromLink] = signFromLink()
        const {
          can,
          reason
        } = canSign(res.result.status, res.result.currentSigningOrder, res.result.participants, isFromLink, data.signingContactInfo)
        if (!can) {
          router.replace({
            name: 'Contract',
            params: { documentId: di },
            query: {
              reason,
              redirectUrl
            }
          })
          store.dispatch('setContractDetail', res.result)
          return false
        }
        router.replace({
          name: 'Authentication',
          params: { documentId: di },
          query: {
            pi,
            redirectUrl
          }
        })
      }).catch(() => {
        Modal.error({
          content: '사용할 수 없는 링크입니다'
        })
      })
    }

    const createTemplateAction = data => {
      const redirectUrl = data.redirectUrl ? encodeURIComponent(data.redirectUrl) : null
      setToken(data)
      getUserInfo().then((res) => {
        const { result } = res
        store.commit('setUserInfo', result)
        uploadModal.value.show('template', redirectUrl, null)
      }).catch((err) => {
        errorModal(err)
      })
    }

    const temlateModifyAction = data => {
      const redirectUrl = data.redirectUrl ? encodeURIComponent(data.redirectUrl) : null
      setToken(data)
      getUserInfo().then((res) => {
        const { result } = res
        store.commit('setUserInfo', result)
        router.push({
          name: 'TemplateEditting',
          params: { templateId: data.documentId },
          query: { redirectUrl }
        })
      }).catch((err) => {
        errorModal(err)
      })
    }

    const setToken = data => {
      store.commit('setToken', data.userToken)
    }

    const setAnonUser = data => {
      storage.set('LINK_KEY', data.linkKey)
      storage.set('CONTACT_INFO', data.signingContactInfo)
      storage.set('SIGN_METHOD_TYPE', data.signingMethodType)
    }

    const errorModal = (err = null) => {
      Modal.error({
        content: '사용할 수 없거나 만료된 링크입니다.',
        onOk () {
          window.location.href = 'https://ucansign.com'
        }
      })
    }

    return {
      uploadModal
    }
  }
}
</script>
